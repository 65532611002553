html, body {
  min-width: 1200px !important;
  overflow-x: visible !important;
}

#root {
  height: 100%;
}

#root > .ui.middle.aligned.grid {
  height: 100%;
}

.ui.form > .field:last-child {
  margin-top: 30px;
}

.ui.form .ui.button {
  min-width: 120px;
  min-height: 40px;
}

.ui.form .add-image {
  display: block;
  width: 120px;
  height: 120px;
  margin: 15px 0;
  border: 1px solid #e0e1e2;
  position: relative;
}

.ui.form .add-image img {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 35px;
  left: 35px;
}

.ui.form .scoop-input img {
  max-height: 120px;
}

.ui.modal > .content {
  padding: 40px 75px 70px !important;
}

.ui.modal > .content > h1 {
  margin-bottom: 30px !important;
}
